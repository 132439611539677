//*********************************************
// We should be using the colors as specified in the color style guide:
// https://app.zeplin.io/project/5ee117693c92f59b0bfd9934/screen/5f448765a4b6657463b4dc89
//
// Before adding a new color, color check if a similar one exists or reach out to
// design for guidance.
//
// The color titles in the comments beside each color are the
// corresponding blueprint color titles:
// node_modules/@blueprintjs/core/lib/scss/variables.scss
//*********************************************

$white: #ffffff;
$black: #000000;

// Primary - Blues
// ----------------
$primary-light: #48aff0; // blue5
$primary-light2: #188fff;
$primary: #2b95d6; // blue4
$primary-dark: #2965cc; // cobalt3
$primary-darker: #1f4b99; // cobalt1

// Primary - Grays
// ----------------

// Light Grays
$primary-light-gray1: #f5f8fa; // light-gray5
$primary-light-gray5: #e1e8ed; // light-gray3
$primary-light-gray2: #d8e1e8; // light-gray2
$primary-light-gray3: #bfccd6; // gray5
$primary-light-gray6: #f5f5f9;
$primary-light-gray7: #e0e4e9;
$primary-light-gray8: #eee;
$primary-light-gray9: #f0f3f5;

// Medium Grays
$primary-medium-gray1: #a7b6c2; // gray4
$primary-light-gray4: #8a9ba8; // gray3
$primary-dark-gray1: #738694; // gray2
$primary-dark-gray2: #5c7080; // gray1

// Dark Grays
$primary-dark-gray3: #394b59; // dark-gray5
$primary-dark-gray4: #202b33; // dark-gray2
$primary-dark-gray5: #464e56;
$primary-dark-gray6: #2d353e;
$dark-gray1: #182026; // dark-gray1

// Secondary Colors
// ----------------
$red: #db3737; // red3
$orange: #d9822b; // orange3
$gold5: #ffc940; // gold5
$green: #29a634; // forest3
$green-hovered: #00873c;
$turquoise: #14ccbd; // turquoise4
$indigo: #7157d9; // indigo3

// Variations based on styleguide colors
// ----------------

$primary-transparent-2: rgba($primary-light, 0.15); //#e4f3fd
$primary-transparent-3: rgba($primary-dark, 0.15); // #dfe8f8
$primary-transparent-4: rgba($primary-dark-gray3, 0.4); // #b1b7bc

// Non-styleguide colors
// ----------------

// NCC colors.  These should be re-vetted to match the styleguide colors
$black-light-3: #212121;
$black-light-4: #333333;
$transparent-6: rgba(245, 245, 245, 0.6);

$gray-light14: #d8d8d8;
$gray-dark3: #757575;
$gray-dark5: #93a2b7;
$gray-dark6: #838383;
$gray-dark7: #4c4c4c;

$gray: #9fa4a8;
$gray-light8: #9b9b9b;
$grey-dark2: #666d74;

// Sidebar colors.  These should be verified for consistency
$nav-border: 1px solid rgba(0, 0, 0, 0.12);
$sidebar-bg: #ebf1f5;
$sidebar-text: #5f6d78;

// Shield
$turmeric: #ffa700;

//CCM debugger {
$pink: pink;
$ccm-green: #dcedc8;
$ccm-red: #f8bbd0;

// The Factual preview UI colors
$factual-gray-dark: #232a31;
$factual-gray-light: #6e7780;
$factual-primary-blue: #0f69ff;
$factual-green: #009751;
$factual-turmeric: #de7c14;
$factual-red: #eb0f29;
$factual-orange: #ff520d;
$factual-purple: #7e1fff;

//Aol preview
$aol-blue: #0063eb;
