@import '/src/styles/colors';

.spinner {
  display: inline-block;
  height: 17px;
  width: 17px;
  margin: 0 2px;

  .bounce1,
  .bounce2,
  .bounce3 {
    animation: bouncedelay 1.4s infinite ease-in-out both;
    background-color: currentColor;
    border-radius: 100%;
    display: inline-block;
    height: 4px;
    margin-left: 1px;
    vertical-align: middle;
    width: 4px;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }

  &.centered {
    margin: 0 auto;
    text-align: center;
    display: flex;
  }

  &.extraLeftMargin {
    margin: 15px 20px 0;
  }
}

.spinner-btn {
  width: 100%;

  .bounce1,
  .bounce2,
  .bounce3 {
    height: 10px;
    background-color: $white;
    width: 10px;
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-4px);
  }
}
